@import '../../styles/variables.scss';

.item {
	margin-bottom: 50px;

	@media (max-width: $mobile-width) {
		display: flex;
		margin: 0 auto;
		margin-bottom: 50px;
	}
}

.image {
	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
	}
}
