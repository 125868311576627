@import '../../styles/variables.scss';

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	padding: 70px 50px;
	background: url('../../images/1.png') left 2% top 10px / auto auto no-repeat, url('../../images/4_1.png') left 45% bottom 2% / auto auto no-repeat, $pink-bg;
	display: flex;
	box-shadow: 0px 0px 8px 0px rgba(239, 222, 216, 0.58);

	@media (max-width: $tablet-width) {
		padding: 30px 20px;
	}

	@media (max-width: $mobile-width) {
		flex-direction: column;
		justify-content: center;
		background: url('../../images/1.png') left -60px top -80px / auto auto no-repeat, url('../../images/4_1.png') right -40px bottom -40px / auto auto no-repeat, $pink-bg;
	}
}

.wrapper {
	margin-right: 50px;
	padding: 20px;
	padding-top: 80px;
	background: url('../../images/quotes.png?1536037264471') left 50% top 30px / auto auto no-repeat rgba(255, 255, 255, 0.6);

	@media (max-width: $mobile-width) {
		margin-right: 0;
		padding-top: 50px;
		background: url('../../images/quotes.png?1536037264471') left 50% top 10px / auto auto no-repeat rgba(255, 255, 255, 0.6);
	}
}

.image {
	width: 40%;
	height: auto;

	@media (max-width: $mobile-width) {
		display: none;
	}
}

.text {
	margin: 0;

	@media (max-width: $mobile-width) {
		font-size: 14px;
	}
}

.author {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.name {
	font-family: $forum;
	font-size: 24px;
}
