@import '../../styles/variables.scss';

.container {
	color: $white;
	padding-top: 50px;
	padding-bottom: 40px;
	background: repeating-linear-gradient(to bottom right, rgba(162, 139, 131, 0.8) 0%, rgba(162, 139, 131, 0.8) 100%),
		url('../../images/5_1.png?1536038404280') right 0px top 50% / auto auto no-repeat,
		url('../../images/5.png?1536038401169') left 0px top 50% / auto auto no-repeat #a28b83;

	@media (max-width: $mobile-width) {
		background: repeating-linear-gradient(to bottom right, rgba(162, 139, 131, 0.8) 0%, rgba(162, 139, 131, 0.8) 100%), url('../../images/5_1.png?1536038404280') right -150px top 55% / auto auto no-repeat #a28b83;
		padding-top: 30px;
		padding-bottom: 20px;
	}
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding-left: 30px;
	padding-right: 30px;
}

.description {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile-width) {
		align-items: center;
	}
}

.logo {
	font-family: $forum;
	font-size: 30px;
	letter-spacing: 1.5px;

	@media (max-width: $mobile-width) {
		margin-bottom: 10px;
	}
}

.text {
	font-size: 12px;
	text-align: right;

	@media (max-width: $tablet-width) {
		text-align: left;
	}
}
