@import '../../styles/variables.scss';

.container {
	position: relative;
	background: url('../../images/5_1.png?1536119366839') right 0px bottom 0px / auto auto no-repeat, url('../../images/5.png?1536038401169') left 0px bottom 0px / auto auto no-repeat;
	z-index: -100;
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 70px 50px;
	background: url('../../images/1.png') left 42% top 10px / auto auto no-repeat, url('../../images/4_1.png') right 5% bottom / auto auto no-repeat, $pink-bg;
	display: flex;
	align-items: flex-start;
	box-shadow: 0px 0px 8px 0px rgba(239, 222, 216, 0.58);

	@media (max-width: $tablet-width) {
		flex-direction: column;
		align-items: center;
		background: url('../../images/1.png') left 2% top 10px / auto auto no-repeat, url('../../images/4_1.png') right 5% bottom / auto auto no-repeat, $pink-bg;
	}

	@media (max-width: $mobile-width) {
		padding: 30px 20px;
	}
}

.photo {
	width: 500px;
	height: auto;
	border: 15px solid #fff;
	margin-right: 60px;

	@media (max-width: $desktop-width) {
		width: 400px;
	}

	@media (max-width: $tablet-width) {
		width: 500px;
		margin-right: 0;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 500px;
	}
}

.title {
	font-family: $forum;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	margin-top: 10px;
	margin-bottom: 25px;

	@media (max-width: $tablet-width) {
		text-align: center;
	}

	@media (max-width: $mobile-width) {
		font-size: 30px;
		line-height: 30px;
	}
}

.border {
	display: block;
	background: url('../../images/border.png') left bottom / auto auto no-repeat;
	height: 10px;
	margin-bottom: 30px;

	@media (max-width: $tablet-width) {
		background-position: center bottom;
	}
}

.list {
	margin-right: 100px;
	padding-left: 20px;
	list-style: none;

	@media (max-width: $tablet-width) {
		margin-right: 0;
	}

	@media (max-width: $mobile-width) {
		font-size: 14px;
	}
}

.item {
	position: relative;
	margin-bottom: 15px;

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		left: -20px;
		width: 10px;
		height: 10px;
		transform: translateY(-50%);
		background-image: url('../../images/border.png');
	}
}
