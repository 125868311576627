@import '../../styles/variables.scss';

.container {
	padding-bottom: 50px;
	position: relative;
	background: url('../../images/5_1.png?1536119366839') right 0px bottom 0px / auto auto no-repeat, url('../../images/5.png?1536038401169') left 0px bottom 0px / auto auto no-repeat;

	@media (max-width: $mobile-width) {
		background: none;
	}
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
}

.wrapper {
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.wrap {
	max-width: 1200px;
	display: flex;
	align-items: center;
	padding: 30px;
	background: url('../../images/2.png') left 45% top 2% / auto auto no-repeat, url('../../images/4_1.png') right 2% bottom 2% / auto auto no-repeat #efded8;
	margin: 0 20px;

	@media (max-width: $mobile-width) {
		flex-direction: column;
		align-self: center;
		padding: 15px;
		margin: 0;
		background: url('../../images/2.png') left 2% top 2% / auto auto no-repeat #efded8;
	}
}

.photo {
	width: 40%;
	height: auto;
	margin-right: 50px;
	border: 15px solid #fff;

	@media (max-width: $desktop-width) {
		margin-right: 20px;
	}

	@media (max-width: $mobile-width) {
		margin-right: 0;
		border: 5px solid #fff;
	}
}

.list {
	padding-left: 20px;
	list-style: none;
	font-size: 14px;
}

.item {
	position: relative;
	margin-bottom: 15px;

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		left: -20px;
		width: 10px;
		height: 10px;
		transform: translateY(-50%);
		background-image: url('../../images/border.png');
	}
}

.phones {
	display: flex;
	align-items: center;

	@media (max-width: $mobile-width) {
		justify-content: center;
	}
}

.phone {
	text-decoration: none;
	color: $green;
	font-family: $font-regular;
	transition: all 0.5s ease;

	&:hover {
		color: $green-dark;
	}
}

.text {
	margin-left: 20px;
}

.socials {
	display: flex;
	justify-self: center;
	margin-bottom: 30px;

	@media (max-width: $desktop-width) {
		margin-bottom: 0;
	}

	@media (max-width: $mobile-width) {
		justify-content: center;
	}
}

.list_social {
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}

.item_social {
	margin-right: 6px;

	&:last-child {
		margin-right: 0;
	}
}

.social_link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: $dark;
	opacity: 0.6;
	transition: all 0.5s ease;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
}
