@import '../../styles/variables.scss';

.header_top_wrapper {
	background-color: $pink-bg;
	position: relative;
}

.header_top {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	background-color: $pink-bg;
	color: $dark;
	padding: 20px 30px;

	@media (max-width: $mobile-width) {
		padding: 15px 20px;
		grid-template-columns: 1fr;
	}
}

.logo {
	font-family: $forum;
	font-size: 30px;
	letter-spacing: 1.5px;

	@media (max-width: $mobile-width) {
		font-size: 26px;
	}
}

.socials {
	justify-self: center;

	@media (max-width: $mobile-width) {
		display: none;
	}

	&_mobile {
		@media (max-width: $mobile-width) {
			display: flex;
			margin: 0 auto;
			margin-bottom: 20px;
		}
	}
}

.item_social {
	margin-right: 6px;

	&:last-child {
		margin-right: 0;
	}
}

.social_link {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: $dark;
	opacity: 0.6;
	transition: all 0.5s ease;

	&:hover {
		opacity: 0.8;
	}
}

.phones {
	justify-self: end;
	display: flex;
	flex-direction: column;

	@media (max-width: $mobile-width) {
		display: none;
	}

	&_mobile {
		@media (max-width: $mobile-width) {
			display: flex;
			margin: 0 auto;
			margin-bottom: 20px;
		}
	}
}

.phone {
	text-decoration: none;
	color: $green;
	font-family: $font-bold;
	transition: all 0.5s ease;

	&:hover {
		color: $green-dark;
	}
}

.text {
	margin: 0;
	text-align: right;

	@media (max-width: $mobile-width) {
		text-align: center;
	}
}

.wrapper {
	background: radial-gradient(ellipse farthest-corner at center center, rgba(56, 40, 40, 0) 68.4%, rgba(56, 40, 40, 0.53) 100%), linear-gradient(rgba(56, 40, 40, 0.5), rgba(56, 40, 40, 0.5)), url('../../images/bg-back.jpg') left 50% top 50% / cover no-repeat;
	height: 700px;

	@media (max-width: $tablet-width) {
		height: 500px;
	}

	@media (max-width: $mobile-width) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 450px;
	}
}

.nav {
	max-width: 1200px;
	margin: 0 auto;

	&_closed {
		display: none;
	}

	&_opened {
		@media (max-width: $mobile-width) {
			display: flex;
			flex-direction: column;
			position: absolute;
			width: 100%;
			background-color: $pink-bg;
		}
	}
}

.list {
	list-style: none;
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
}

.list_mobile {
	list-style: none;
	color: $dark;
	padding: 0;
}

.list_nav {
	color: $white;
	text-transform: uppercase;
	font-family: $font-medium;
	font-size: 14px;
	background: url('../../images/border_2.png') left calc(50% - 9px) bottom 0px / auto auto no-repeat;

	@media (max-width: $mobile-width) {
		display: none;
	}
}

.item {
	width: auto;
	width: 100%;
}

.link {
	display: flex;
	justify-content: center;
	color: $white;
	text-decoration: none;
	width: 100%;
	padding: 20px 15px 30px;
	transition: all 0.5s ease;

	&:hover {
		color: $green;
	}

	@media (max-width: $mobile-width) {
		color: $dark;
		padding: 15px;
	}
}

.description {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: $white;
	font-family: $forum;
	font-size: 50px;
	line-height: 30px;

	@media (max-width: $mobile-width) {
		font-size: 35px;
		line-height: 60px;
	}
}

.title {
	margin-top: 200px;
	margin-bottom: 0;

	@media (max-width: $tablet-width) {
		margin-top: 100px;
	}

	@media (max-width: $mobile-width) {
		text-align: center;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.text_bg {
	margin-bottom: 150px;

	@media (max-width: $tablet-width) {
		margin-bottom: 80px;
	}

	@media (max-width: $mobile-width) {
		text-align: center;
		margin-top: 0;
		margin-bottom: 60px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.button {
	font-size: 16px;
	font-family: $font-regular;
	color: $white;
	text-decoration: none;
	background-color: $green;
	padding: 15px 40px;
	border-radius: 50px;
	transition: all 0.5s ease;

	&:hover {
		background-color: $green-dark;
		opacity: 0.8;
	}

	@media (max-width: $mobile-width) {
		padding: 15px 20px;
		line-height: 20px;
	}
}

.menu_button {
	display: none;

	@media (max-width: $mobile-width) {
		position: absolute;
		top: 28px;
		transform: translateY(-50%);
		right: 0;
		z-index: 2;
		display: block;
		width: 48px;
		height: 48px;
		background-image: url("../../images/menu-open.svg");
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		cursor: pointer;
		border: none;
		padding: 0;

		&_open {
			background-image: url("../../images/menu-close.svg");
		}
	}
}
