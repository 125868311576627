// $black: #202020;
// $blue-title: #1B118F;
$white: #ffffff;
$active: #a28b83;
$dark: #62544f;
$green: #7c9449;
$green-dark: #5a6b35;
$pink-bg: #efded8;

/*font*/
$font-regular: "Montserrat Regular", "Arial", sans-serif;
$font-medium: "Montserrat Medium", "Arial", sans-serif;
$font-bold: "Montserrat Bold", "Arial", sans-serif;
$forum: "Forum", "Arial", cursive;


/*viewports*/
$max-desktop-width: 1499px;
$desktop-width: 1199px;
$tablet-width: 1023px;
$mobile-width: 743px;
