@import '../../styles/variables.scss';

.reviews {
	margin-bottom: 50px;
	padding-top: 50px;

	@media (max-width: $mobile-width) {
		padding-top: 10px;
		min-width: 320px;
	}
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
}

.wrap {
	width: 500px;
	margin: 0 auto;
}

:root {
	--swiper-pagination-color: #a28b83;
	;
}
