@import '../../styles/variables.scss';

.container {
	background: url('../../images/5_1.png?1536119366839') right 0px bottom 0px / auto auto no-repeat,
		url('../../images/5.png?1536038401169') left 0px bottom 0px / auto auto no-repeat #efded8;

	&::before {
		display: block;
		content: "";
		position: relative;
		height: 34px;
		background-image: url('../../images/wave.png');
		background-repeat: repeat;
		background-position: top;
		background-size: auto;
	}

	&::after {
		display: block;
		content: "";
		position: relative;
		height: 34px;
		background-image: url('../../images/wave-bottom.png');
		background-repeat: repeat;
		background-position: top;
		background-size: auto;
	}
}

.wrapper {
	max-width: 700px;
	margin: 0 auto;
	padding-top: 50px;
	padding-bottom: 50px;
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
}
