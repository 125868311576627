@import '../../styles/variables.scss';

.container {
	background: url('../../images/bg_contacts.jpg') left 50% bottom 10% / cover no-repeat;
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 700px;
	padding: 30px;
	background: url('../../images/2.png') left 2% top 2% / auto auto no-repeat, url('../../images/4_1.png') right 2% bottom 2% / auto auto no-repeat #efded8;
	margin: 50px 20px;

	@media (max-width: $tablet-width) {
		width: 500px;
	}

	@media (max-width: $mobile-width) {
		width: 100%;
		margin: 50px 0;
	}
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
	margin-bottom: 20px;
}

.border {
	display: flex;
	background: url('../../images/border.png') left bottom / auto auto no-repeat;
	height: 10px;
	width: 245px;
	margin: 0 auto;
}

.text {
	text-align: center;
	padding-left: 100px;
	padding-right: 100px;
	font-size: 18px;

	@media (max-width: $tablet-width) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (max-width: $mobile-width) {
		font-size: 16px;
		padding-left: 0;
		padding-right: 0;
	}
}

form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-left: 70px;
	padding-right: 70px;
	font-family: $font-regular;

	@media (max-width: $tablet-width) {
		padding-left: 40px;
		padding-right: 40px;
	}

	@media (max-width: $mobile-width) {
		padding-left: 0;
		padding-right: 0;
	}
}

.labels {
	display: flex;
	margin-bottom: 10px;

	@media (max-width: $mobile-width) {
		flex-direction: column;
	}
}

.label {
	width: 50%;
	margin-right: 10px;

	@media (max-width: $mobile-width) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}

	&:last-child {
		margin-right: 0;

		@media (max-width: $mobile-width) {
			margin-bottom: 0;
		}
	}
}

.input {
	width: 100%;
	border: none;
	padding: 15px;
	font-size: 15px;
	font-family: $font-regular;
}

.textarea {
	width: 100%;
	border: none;
	padding: 15px;
	font-size: 14px;
	font-family: $font-regular;
	margin-bottom: 4px;
}

.button {
	margin: 0 auto;
	font-size: 16px;
	font-family: $font-regular;
	color: $white;
	text-decoration: none;
	background-color: $green;
	padding: 15px 40px;
	border-radius: 50px;
	transition: all 0.5s ease;
	border: none;
	margin-top: 8px;

	&:hover {
		background-color: $green-dark;
		opacity: 0.8;
	}

	@media (max-width: $mobile-width) {
		padding: 15px 20px;
		line-height: 20px;
	}
}

.captcha {
	@media (max-width: $mobile-width) {
		display: block;
		width: 100%;
		transform: scale(0.9);
		transform-origin: 0 0;
	}

	@media (max-width: 412px) {
		display: block;
		width: 100%;
		transform: scale(0.7);
	}
}
