@import '../../styles/variables.scss';

.container {
	background:
		url('../../images/4_1.png?1536038404280') right 0px top 50% / auto auto no-repeat,
		url('../../images/1.png?1536038401169') left 0px top 50% / auto auto no-repeat #efded8;

	&::before {
		display: block;
		content: "";
		position: relative;
		height: 34px;
		background-image: url('../../images/wave.png');
		background-repeat: repeat;
		background-position: top;
		background-size: auto;
	}

	&::after {
		display: block;
		content: "";
		position: relative;
		height: 34px;
		background-image: url('../../images/wave-bottom.png');
		background-repeat: repeat;
		background-position: top;
		background-size: auto;
	}
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 10px;
}

.title {
	font-family: $forum;
	font-weight: 400;
	text-align: center;
	font-size: 40px;
	line-height: 40px;
}
