@import '../../styles/variables.scss';

.services {
	padding-top: 50px;
	padding-left: 50px;
	padding-right: 50px;

	@media (max-width: $desktop-width) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@media (max-width: $tablet-width) {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media (max-width: $mobile-width) {
		padding-top: 10px;
	}
}

.title {
	font-family: $forum;
	font-size: 40px;
	line-height: 40px;
	font-weight: 400;
	text-align: center;
}

:root {
	--swiper-navigation-color: white;
	--swiper-theme-color: white;

	@media (max-width: $mobile-width) {
		--swiper-navigation-color: #7c9449;
		--swiper-theme-color: #7c9449;
	}
}

:global(.swiper-slide) {
	height: auto;
}
