@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'),
    url('../fonts/montserrat-regular.woff2') format('woff2'),
    url('../fonts/montserrat-regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'),
    url('../fonts/montserrat-medium.woff2') format('woff2'),
    url('../fonts/montserrat-medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'),
    url('../fonts/montserrat-bold.woff2') format('woff2'),
    url('../fonts/montserrat-bold.woff') format('woff');
}

@font-face {
  font-family: 'Forum';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Forum'),
    url('../fonts/forum.woff2') format('woff2'),
    url('../fonts/forum.woff') format('woff');
}
