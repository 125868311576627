@import '../../styles/variables.scss';

.item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.image {
	max-width: 100%;
	height: auto;

	@media (max-width: $mobile-width) {
		width: 100%;
		max-width: 300px;
	}
}

.title {
	font-family: $forum;
	font-weight: 400;
	font-size: 26px;
	text-align: center;
	color: $dark;

	@media (max-width: $tablet-width) {
		font-size: 22px;
		line-height: 22px;
		margin-bottom: 15px;
	}
}

.border {
	display: block;
	background: url('../../images/border_1.png') left bottom / auto auto no-repeat;
	height: 10px;
	width: 48px;
	margin-top: auto;
}

.time {
	@media (max-width: $tablet-width) {
		margin-bottom: 10px;
	}
}

.prices {
	display: flex;
}

.price {
	margin-top: 0;
}

.bold {
	font-weight: 700;
	color: $green-dark;
}
