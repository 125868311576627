.wrapper {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	margin: 0 auto;
	min-height: 100vh;
	grid-template-columns: 100%;
}

.scroll {
	border-radius: 9999px !important;
	width: 50px !important;
	height: 50px !important;
	opacity: 0.7 !important;
	right: 20px !important;
	bottom: 20px !important;
}
